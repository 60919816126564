import styled from 'styled-components';

export const StyledLanguageSelectDiv = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;

    @media (max-width: 768px) {
        gap: 0.5rem;
    }
`;

export const StyledLanguageSelectImg = styled.img`
    cursor: pointer;
    width: 50%;
    transition: var(--transition);

    &:hover {
        opacity: 0.5;
    }
`;
