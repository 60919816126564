import styled from 'styled-components';

export const StyledAboutPageDiv = styled.div`
    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        display: none;
        opacity: 0;
        overflow-y: hidden;
    }

    h1 {
        margin-top: 2.5rem;
        text-align: center;
        font-size: 64px;
        font-weight: 800;
        color: var(--color-font-red);
        display: none;
        opacity: 0;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 38px;
        }
    }
`;

export const StyledAboutSection = styled.section`
    width: 90%;
    margin: 3rem auto;
    display: flex;
    gap: 3rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledAboutParDiv = styled.div`
    width: 100%;
    display: none;
    opacity: 0;

    p {
        font-size: 22px;
    }

    @media (max-width: 768px) {
        p {
            font-size: 20px;
            text-align: center;
        }
    }
`;

export const StyledAboutServicesDiv = styled.div`
    width: 100%;
    position: relative;
    display: none;
    opacity: 0;

    h4 {
        font-size: 28px;
    }

    p {
        font-size: 22px;
    }

    &::before {
        content: '';
        position: absolute;
        left: -2rem;
        height: 100%;
        width: 4px;
        background-color: var(--color-font-red);
    }

    @media (max-width: 768px) {
        h4 {
            font-size: 26px;
        }

        p {
            font-size: 20px;
        }

        &::before {
            left: 0;
            top: -1.5rem;
            height: 4px;
            width: 100%;
        }
    }
`;

export const StyledAboutList = styled.ul`
    list-style: circle;
    padding-left: 1rem;

    li {
        font-size: 22px;
        font-weight: 500;
    }

    @media (max-width: 768px) {
        li {
            font-size: 20px;
        }
    }
`;
