import React from 'react';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { StyledNav, StyledNavHeader, StyledNavLogo, StyledNavLink, StyledNavHeaderDiv } from './Nav.styled';

import logo from '../../assets/logotipas.png';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

export default function Nav({ setLocale }) {
    return (
        <StyledNavHeader>
            <StyledNavHeaderDiv>
                <Link to={'/'}>
                    <StyledNavLogo src={logo} alt="Laikrida Logo" />
                </Link>
                <StyledNav>
                    <StyledNavLink to={'/'}>
                        <FormattedMessage id="about-link" defaultMessage="APIE MUS" />
                    </StyledNavLink>
                    <StyledNavLink to={'/karjera'}>
                        <FormattedMessage id="career-link" defaultMessage="KARJERA" />
                    </StyledNavLink>
                    <StyledNavLink to={'/kontaktai'}>
                        <FormattedMessage id="contact-link" defaultMessage="KONTAKTAI" />
                    </StyledNavLink>
                </StyledNav>
                <LanguageSelect setLocale={setLocale} />
            </StyledNavHeaderDiv>
        </StyledNavHeader>
    );
}
