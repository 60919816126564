import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledCareerDiv = styled.div`
    width: 95%;
    margin: 5rem auto;
    display: flex;

    img {
        width: 50%;
    }

    @media (max-width: 1240px) {
        flex-direction: column;
        gap: 3rem;

        img {
            width: 100%;
        }
    }
`;

export const StyledCareerHeadersDiv = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;

    h1,
    a {
        font-size: 58px;
        font-weight: 800;
    }

    @media (max-width: 1240px) {
        width: 100%;
        flex-direction: column;
        gap: 3rem;
    }

    @media (max-width: 768px) {
        h1,
        a {
            font-size: 30px;
        }
    }
`;

export const StyledCareerTitle = styled.h1`
    color: var(--color-font-black);
`;

export const StyledCareerLink = styled(Link)`
    color: var(--color-font-red);
    transition: var(--transition);

    &:hover {
        color: var(--color-font-hover);
    }
`;
