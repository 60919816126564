import React, { useEffect } from 'react';
import gsap from 'gsap';

import ContactCards from './ContactCards/ContactCards';
import { StyledContactMap } from './ContactPage.styled';

export default function ContactPage({ locale }) {
    useEffect(() => {
        gsap.from(['#cards', '#map'], {
            yPercent: '-25',
            display: 'none',
            opacity: 0,
        });
        gsap.to(['#cards', '#map'], {
            yPercent: '0',
            display: 'flex',
            opacity: 1,
            ease: 'power1.out',
            duration: 1.5,
        });
    }, [locale]);

    return (
        <div>
            <ContactCards />
            <StyledContactMap
                id="map"
                title="Laikrida"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d279.23303822899766!2d23.31301847995079!3d55.95172376443533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e5e33aa1590125%3A0x356d380ed243b0a3!2sLaikrida!5e0!3m2!1slt!2slt!4v1705685735851!5m2!1slt!2slt"
                height="600"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></StyledContactMap>
        </div>
    );
}
