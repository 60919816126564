import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledNavHeader = styled.header`
    width: 100%;
    background-color: var(--color-bg-secondary);
`;

export const StyledNavHeaderDiv = styled.div`
    width: 95%;
    padding: 1rem 0;
    margin: auto;
    display: grid;
    grid-template-columns: 0.75fr 3fr 0.5fr;
    align-items: center;

    @media (max-width: 1240px) {
        grid-template-columns: 1.25fr 3fr 0.75fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 0 4fr 0.75fr;
    }
`;

export const StyledNavLogo = styled.img`
    cursor: pointer;
    width: 100%;
    transition: var(--transition);

    &:hover {
        opacity: 0.7;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledNav = styled.nav`
    width: 100%;
    text-align: center;

    @media (max-width: 768px) {
        text-align: left;
    }
`;

export const StyledNavLink = styled(Link)`
    color: var(--color-font-red);
    margin-left: 2rem;
    font-size: 22px;
    font-weight: 500;
    transition: var(--transition);

    &:hover {
        letter-spacing: 0.25rem;
        color: var(--color-font-hover);
    }

    @media (max-width: 768px) {
        margin-left: 0.75rem;
        font-size: 18px;

        &:hover {
            letter-spacing: 0.1rem;
        }
    }
`;
