import React from 'react';
import ContactAddress from './ContactAddress';
import ContactInfo from './ContactInfo';
import ContactHours from './ContactHours';
import { StyledContactCardsDiv } from '../ContactPage.styled';

export default function ContactCards() {
    return (
        <StyledContactCardsDiv id="cards">
            <ContactAddress />
            <ContactInfo />
            <ContactHours />
        </StyledContactCardsDiv>
    );
}
