import React from 'react';

import ltFlag from '../../assets/lt-flag.png';
import enFlag from '../../assets/en-flag.png';
import { StyledLanguageSelectDiv, StyledLanguageSelectImg } from './LanguageSelect.styled';

export default function LanguageSelect({ setLocale }) {
    function switchLanguage(locale) {
        setLocale(locale);
    }

    return (
        <StyledLanguageSelectDiv>
            <StyledLanguageSelectImg src={ltFlag} alt="Lithuanian Flag Icon" onClick={() => switchLanguage('lt')} />
            <StyledLanguageSelectImg src={enFlag} alt="English Flag Icon" onClick={() => switchLanguage('en')} />
        </StyledLanguageSelectDiv>
    );
}
