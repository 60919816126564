import React from 'react';
import { StyledContactCard } from '../ContactPage.styled';
import { MdOutlineMapsHomeWork } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

export default function ContactAddress() {
    return (
        <StyledContactCard>
            <i>
                <MdOutlineMapsHomeWork />
            </i>

            <h2>UAB „Laikrida“</h2>
            <h3>
                <FormattedMessage id="contact-address1" defaultMessage="Įm. kodas: 145257025" />
            </h3>
            <h3>
                <FormattedMessage id="contact-address2" defaultMessage="PVM kodas: LT452570219" />
            </h3>
            <h3>
                <FormattedMessage
                    id="contact-address3"
                    defaultMessage="J. Basanavičiaus g. 67a,
                    LT-76178 Šiauliai"
                />
            </h3>
            <h3>AB "Swedbank": LT53 7300 0100 7820 2248</h3>
        </StyledContactCard>
    );
}
