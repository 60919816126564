import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import gsap from 'gsap';

import { StyledAboutList, StyledAboutPageDiv, StyledAboutParDiv, StyledAboutSection, StyledAboutServicesDiv } from './AboutPage.styled';

import About1 from '../../assets/about1.jpeg';
import About2 from '../../assets/about2.jpg';

export default function AboutPage({ locale }) {
    useEffect(() => {
        gsap.from('#top-image', {
            yPercent: '15',
            display: 'none',
            opacity: 0,
        });
        gsap.from('#bottom-image', {
            yPercent: '-15',
            display: 'none',
            opacity: 0,
        });
        gsap.from('#left-animation', {
            xPercent: '-10',
            display: 'none',
            opacity: 0,
        });
        gsap.from('#right-animation', {
            xPercent: '10',
            display: 'none',
            opacity: 0,
        });
        gsap.from('#title', {
            display: 'none',
            opacity: 0,
        });
        gsap.to(['#top-image', '#bottom-image', '#left-animation', '#right-animation'], {
            xPercent: '0',
            yPercent: '0',
            display: 'initial',
            opacity: 1,
            ease: 'power1.out',
            duration: 1.5,
        });
        gsap.to('#title', {
            display: 'inherit',
            opacity: 1,
            ease: 'power1.out',
            duration: 2,
        });
    }, [locale]);

    return (
        <StyledAboutPageDiv>
            <img id="top-image" src={About1} alt="A row of trucks" />

            <h1 id="title">
                <FormattedMessage id="about-header" defaultMessage="Istorija ir paslaugos" />
            </h1>

            <StyledAboutSection>
                <StyledAboutParDiv id="left-animation">
                    <p>
                        <FormattedMessage
                            id="about-par1"
                            defaultMessage="UAB„Laikrida“ buvo įkurta 1996 metais . Pagrindinė įmonės veikla- tarptautinių krovinių
                        vežimas sausumos keliais. Pervežimus vykdome Vakarų Europos šalyse. UAB „Laikrida“ yra
                        asociacijos „Linava“ narė."
                        />
                    </p>
                    <br />
                    <p>
                        <FormattedMessage
                            id="about-par2"
                            defaultMessage="Mūsų tikslas - savo klientams pasiūlyti tik aukštos kokybės paslaugas, būti tarp greičiausių
                        ir patikimiausių transporto paslaugų teikėjų. UAB „Laikrida” vertybinė sistema orientuota į
                        esamą ir būsimą klientą. Vienas iš įmonės prioritetų - glaudūs klientų ir įmonės atstovų
                        santykiai."
                        />
                    </p>
                </StyledAboutParDiv>

                <StyledAboutServicesDiv id="right-animation">
                    <h4>
                        <FormattedMessage id="about-parh1" defaultMessage="Paslaugos" />
                    </h4>
                    <StyledAboutList>
                        <li>
                            <FormattedMessage id="about-li1" defaultMessage="Krovinių gabenimas tarptautiniais maršrutais." />
                        </li>
                        <li>
                            <FormattedMessage
                                id="about-li2"
                                defaultMessage="Pilnų ir dalinių krovinių pervežimas užuolaidiniais tentais."
                            />
                        </li>
                        <li>
                            <FormattedMessage id="about-li3" defaultMessage="Atliekų gabenimas." />
                        </li>
                    </StyledAboutList>

                    <br />
                    <h4>
                        <FormattedMessage id="about-parh2" defaultMessage="Transporto priemonių parkas" />
                    </h4>

                    <p>
                        <FormattedMessage
                            id="about-par3"
                            defaultMessage="Transporto parką sudaro apie 100 vilkikų su puspriekabėmis. Visi vilkikai atitinka
                        aukščiausią ekologijos standartą -Euro 6 klasę. Mažindami degalų sunaudojimą,
                        betarpiškai prisidedame prie ekologiškos ir tvarios aplinkos kūrimo. Mūsų vilkikuose yra
                        sumontuotos GPS sekimo sistemos, kurios leidžia juos nuolat stebėti pakeliui į paskirties
                        vietą."
                        />
                    </p>
                </StyledAboutServicesDiv>
            </StyledAboutSection>

            <img id="bottom-image" src={About2} alt="A road with vehicles at night" />
        </StyledAboutPageDiv>
    );
}
