import React from 'react';
import { StyledContactCard } from '../ContactPage.styled';
import { FaAddressBook } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

export default function ContactInfo() {
    return (
        <StyledContactCard>
            <i>
                <FaAddressBook />
            </i>

            <h3>
                <FormattedMessage id="contact-info1" defaultMessage="Tel.: " />
                +370 41 500505
            </h3>
            <h3>Fax: +370 41 500501</h3>
            <h3>
                <FormattedMessage id="contact-info2" defaultMessage="Mob.: " />
                <a href="tel:+370 655 07617">+370 655 07617</a>
            </h3>
            <h3>
                <FormattedMessage id="contact-info3" defaultMessage="El.paštas: " />
                <a href="mailto:info@laikrida.lt">info@laikrida.lt</a>
            </h3>
            <h3>
                <FormattedMessage id="contact-info4" defaultMessage="Darbo El.paštas: " />
                <a href="mailto:darbas@laikrida.lt">darbas@laikrida.lt</a>
            </h3>
        </StyledContactCard>
    );
}
