import React from 'react';
import { StyledContactHoursCard } from '../ContactPage.styled';
import { PiClockCountdown } from 'react-icons/pi';
import { FormattedMessage } from 'react-intl';

export default function ContactHours() {
    return (
        <StyledContactHoursCard>
            <i>
                <PiClockCountdown />
            </i>
            <h2>
                <FormattedMessage id="contact-hours1" defaultMessage="Darbo Laikas" />
            </h2>
            <h3>I - V: 8:00 - 17:00</h3>
            <h3>
                VI-VII:{' '}
                <span>
                    <FormattedMessage class="contact-hours" id="contact-hours2" defaultMessage="Nedirbame" />
                </span>
            </h3>
        </StyledContactHoursCard>
    );
}
