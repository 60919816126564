import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Routes, Route } from 'react-router-dom';

import enTranslations from './locales/en.json';
import ltTranslations from './locales/lt.json';

import Nav from './components/Nav/Nav';

import AboutPage from './pages/About/AboutPage';
import CareerPage from './pages/Career/CareerPage';
import ContactPage from './pages/Contact/ContactPage';
import Footer from './components/Footer/Footer';

function App() {
    const [locale, setLocale] = useState('lt');
    const messages = {
        en: enTranslations,
        lt: ltTranslations,
    };

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <Nav setLocale={setLocale} />

            <Routes>
                <Route path="/karjera" element={<CareerPage locale={locale} />} />
                <Route path="/kontaktai" element={<ContactPage locale={locale} />} />
                <Route path="/" element={<AboutPage locale={locale} />} />
            </Routes>

            <Footer />
        </IntlProvider>
    );
}

export default App;
