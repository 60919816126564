import styled from 'styled-components';

export const StyledContactCardsDiv = styled.div`
    width: 85%;
    margin: 5rem auto;
    display: flex;
    gap: 6rem;
    display: none;
    opacity: 0;

    @media (max-width: 1240px) {
        width: 60%;
        text-align: center;
        flex-direction: column;
        gap: 3rem;
    }

    @media (max-width: 768px) {
        width: 85%;
    }
`;

export const StyledContactCard = styled.div`
    width: 100%;
    padding: 1rem;
    text-align: center;
    color: var(--color-font-white);
    background-color: var(--color-bg-card);
    border: 2px solid var(--color-font-hover);
    border-radius: 10px;
    transition: var(--transition);

    i {
        font-size: 64px;
    }

    h2 {
        line-height: 3rem;
        font-weight: 800;
        color: var(--color-font-red);
    }

    h3 {
        line-height: 2rem;
    }

    a {
        color: var(--color-font-red);
        transition: var(--transition);

        &:hover {
            color: var(--color-font-hover);
        }
    }

    &:hover {
        width: 105%;
        opacity: 0.95;
    }
`;

export const StyledContactHoursCard = styled(StyledContactCard)`
    text-align: center;

    i {
        font-size: 82px;
    }

    h3 {
        line-height: 3rem;

        span {
            color: var(--color-font-red);
        }
    }
`;

export const StyledContactMap = styled.iframe`
    width: 95%;
    margin: 2rem 2.5%;
    margin-bottom: 0;
    border: 2px solid var(--color-font-hover);
    border-radius: 15px;
    display: none;
    opacity: 0;
`;
