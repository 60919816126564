import styled from 'styled-components';

export const StyledFooter = styled.footer`
    position: absolute;
    width: 100%;
    padding: 1rem;
    background-color: var(--color-bg-secondary);
    color: var(--color-font-red);
    text-align: center;

    h4 {
        margin-top: 1rem;
        color: var(--color-font-hover);
    }
`;
