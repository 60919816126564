import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import gsap from 'gsap';

import Career from '../../assets/career.jpg';

import { StyledCareerDiv, StyledCareerHeadersDiv, StyledCareerLink, StyledCareerTitle } from './CareerPage.styled';

export default function CareerPage({ locale }) {
    useEffect(() => {
        gsap.from('#title', {
            xPercent: '-5',
            display: 'none',
            opacity: 0,
        });
        gsap.from('#image', {
            xPercent: '5',
            display: 'none',
            opacity: 0,
        });
        gsap.to(['#image', '#title'], {
            xPercent: '0',
            display: 'inherit',
            opacity: 1,
            ease: 'power1.out',
            duration: 1.5,
        });
    }, [locale]);

    return (
        <div>
            <StyledCareerDiv>
                <StyledCareerHeadersDiv id="title">
                    <StyledCareerTitle>
                        <FormattedMessage id="career-header1" defaultMessage="IEŠKOTE VILKIKO VAIRUOTOJO DARBO?" />
                    </StyledCareerTitle>

                    <StyledCareerLink to={'/kontaktai'}>
                        <FormattedMessage id="career-header2" defaultMessage="PRISIJUNKITE PRIE MŪSŲ KOMANDOS" />
                    </StyledCareerLink>
                </StyledCareerHeadersDiv>

                <img id="image" src={Career} alt="People Handshaking" />
            </StyledCareerDiv>
        </div>
    );
}
