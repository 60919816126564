import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledFooter } from './Footer.styled';

export default function Footer() {
    return (
        <StyledFooter>
            <h2>LAIKRIDA</h2>
            <h4>
                © 2024 „Laikrida UAB". <FormattedMessage id="footer-header" defaultMessage="Visos teisės saugomos" />
            </h4>
        </StyledFooter>
    );
}
